import React from "react"
import { Col, Jumbotron, Row } from "react-bootstrap"
import styled from 'styled-components'
import { ArrowRight } from '../components/Images'
import { PublicLayout } from '../components/layout'
import { Buttons, Seo } from '../components/navigation'
import { GrowRow } from '../components/utils'
import PI from '../components/ProgressiveImages'
import { ANIMATION_DURATION_SECS, fadeInRefs, slideInFromRightRefs } from "../utils/Animate"


const Section = styled(({ className = '', ...props }) => <Col xs={12} lg={6} className={`${className} mb-5`} {...props} />)``
const Header = styled(({ className = '', ...props }) => <h2 className={`${className} mb-3`} {...props} />)``
const Content = styled(({ className = '', ...props }) => <p className={`${className} mb-5 text-125`} {...props} />)``

const IndexPage = () => {
  const refFirst = React.useRef(null)
  const refSecond = React.useRef(null)
  const refThird = React.useRef(null)

  React.useEffect(() => {
    slideInFromRightRefs([refFirst, refSecond, refThird], ANIMATION_DURATION_SECS)
    fadeInRefs([refFirst, refSecond, refThird], ANIMATION_DURATION_SECS)
  }, [])

  return <PublicLayout>
    <Seo.Intro />
    <Jumbotron className="bg-white mb-0">
      <Row>
        <Section>
          <div ref={refFirst} style={{ opacity: 0 }}>
            <Header>What is SubClub?</Header>
            <Content>SubClub is an online platform that helps you get the most value for your money.</Content>
          </div>
          <div ref={refSecond} style={{ opacity: 0 }}>
            <Header>Why?</Header>
            <Content>Because 50% of the population don&apos;t reassess their phone bills, and are stuck on antiquated deals that <b>cost more money</b> and <b>provide less service</b>.</Content>
          </div>
          <div ref={refThird} style={{ opacity: 0 }}>
            <Header>How?</Header>
            <Content>Tell us when your phone plan finishes - and when it does, we&apos;ll help you to switch to a better plan!</Content>
          </div>
        </Section>

        <Section className="d-none d-md-flex justify-content-center align-items-start overflow-hidden">
          <PI.WomanWithPhone className="d-flex flex-grow-1 flex-shrink-0" />
        </Section>
      </Row>

      <GrowRow className="justify-content-center mt-5">
        <Buttons.Login>Get started for free <ArrowRight /></Buttons.Login>
      </GrowRow>
    </Jumbotron>
  </PublicLayout>
}

export default IndexPage
